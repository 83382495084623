import React, { useEffect, useState } from "react";
import closeMenuLogo from "/public/images/logo-small.png";
import openMenuLogo from "/public/images/logo-transparent-small.png";
import { ChevronDownIcon } from "@heroicons/react/solid";
import {
  DocumentTextIcon,
  ViewGridIcon,
  UserIcon,
  UsersIcon,
  BellIcon,
  CogIcon,
} from "@heroicons/react/outline";
import Link from "next/link";
import { useRouter } from "node_modules/next/router";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPage,
  setCurrentPageTitle,
  setNavState,
} from "@/redux/actions/globalActions";
import { userService } from "@/services/user-service";
import Avatar from "@/lib/avatar";
import { fetchWrapper } from "@/lib/fetch-wrapper";
import { clsx } from "clsx";
import { resetJobFilters } from "@/redux/actions/jobsFilterActions";
import { forwardRef } from "react";
import { Fragment } from "react";
import Tooltip from "./jobs-v2/components/Tooltip";
import CustomValue from "./jobs/sub/CustomValue";

const MenuItems = [
  {
    label: "Dashboard",
    url: "/",
    icon: {
      active: <ViewGridIcon className="text-green-10 w-5 h-5 lg:w-6 lg:h-6" />,
      notActive: (
        <ViewGridIcon className={`text-gray-10 w-5 h-5 lg:w-6 lg:h-6`} />
      ),
    },
    active: true,
    disabled: true,
    tooltip: "Feature Under Construction",
  },
  {
    label: "Jobs",
    url: "/jobs",
    icon: {
      active: <DocumentTextIcon className="text-green-10 w-5 h-5" />,
      notActive: <DocumentTextIcon className="text-gray-10 w-5 h-5" />,
    },
    active: false,
  },
  {
    label: "Clients",
    url: "/clients",
    icon: {
      active: <UserIcon className="text-green-10 w-6 h-6" />,
      notActive: <UserIcon className="text-gray-10 w-6 h-6" />,
    },
    active: false,
    borderBottom: true,
  },
  {
    label: "Team",
    url: "/team",
    icon: {
      active: <UsersIcon className="text-green-10 w-5 h-5" />,
      notActive: <UsersIcon className="text-gray-10 w-5 h-5" />,
    },
    active: false,
  },
  {
    label: "Settings",
    url: "/settings",
    icon: {
      active: <CogIcon className="text-green-10 w-5 h-5" />,
      notActive: <CogIcon className="text-gray-10 w-5 h-5" />,
    },
    active: false,
  },
  {
    label: "Notifications",
    url: "/notifications",
    icon: {
      active: <BellIcon className="text-green-10 w-5 h-5" />,
      notActive: <BellIcon className="text-gray-10 w-5 h-5" />,
    },
    active: false,
    disabled: true,
    tooltip: "Feature Under Construction",
  },
  // {
  //     label: "User Profile",
  //     url: "/user-profile",
  //     icon: {
  //         active: (
  //             <UserCircleIcon className="text-green-10 w-5 h-5" />
  //         ),
  //         notActive: (
  //             <UserCircleIcon className="text-gray-10 w-5 h-5" />
  //         ),
  //     },
  //     active: false,
  //     hidden: true,
  // },
];

const NavComponent = ({ className }, ref) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [activePath, setActivePath] = useState(null);
  const [hidden, setHidden] = useState(true);
  const open = useSelector((state) => state.global.navState);
  const userState = useSelector((state) => state.user.data);
  const user = userService.userValue;
  const [menuItems, setMenuItems] = useState(MenuItems);
  const [rootUser, setRootUser] = useState(
    userState.root ? userState.root : false
  );

  const handleNavChange = () => {
    dispatch(setNavState(!open));
  };

  // const currentPath = router.asPath;
  const getActivePath = () => {
    const path = router.asPath.replace("#", "");
    const paths = path.split("/").filter((p) => p);

    return paths.length > 1 ? "/".concat(paths[0]) : path;
  };

  function dropupHandler() {
    setHidden(!hidden);
  }

  const handleLogout = async () => {
    dispatch(resetJobFilters());
    let user = userService.userValue;
    if (user && user.hasOwnProperty("user")) {
      user = user.user;
    }
    const url = `${process.env.NEXT_PUBLIC_API_URL}authenticate?`;
    const params = { user: user._id };
    const response = await fetchWrapper.get(url + new URLSearchParams(params));
    (await response) &&
      response.success &&
      response.query.acknowledged &&
      userService.logout();
  };

  useEffect(() => {
    let mounted = true;
    mounted && setActivePath(getActivePath());

    const page = MenuItems.find((i) => i.url === activePath);

    if (page) {
      dispatch(setCurrentPage(page.url));
      dispatch(setCurrentPageTitle(page.label));
    }

    return () => {
      mounted = false;
    };
  }, [activePath, userState]);

  useEffect(() => {
    let updatedMenu = [];
    menuItems.map((menu) => {
      let temp = { ...menu };
      if (rootUser || userState.role.rep === 1) {
        // nothing to do here...
      } else {
        if (menu.label === "Team") {
          temp.hidden = true;
        }
      }

      updatedMenu.push(temp);
    });

    setMenuItems(updatedMenu);
  }, []);

  return (
    <div
      ref={ref}
      className={clsx(
        `${
          open ? "w-64 bg-white" : "w-20 bg-dark-color"
        } h-screen fixed duration-300 top-0 flex flex-col justify-between`,
        className
      )}
    >
      <div>
        <div
          className={`flex items-center ${open && "mx-5 pt-4"}`}
          onClick={handleNavChange}
        >
          <img
            src={` ${open ? openMenuLogo.src : closeMenuLogo.src}`}
            className={`cursor-pointer duration-500 ${
              open && "rotate-[360deg]"
            }`}
          />
        </div>
        <ul className="pt-1 p-5">
          {menuItems.map((item, index) => {
            const Component = item.disabled ? Fragment : Link;

            return (
              !item.hidden && (
                <Component
                  key={index}
                  {...(!item.disabled
                    ? { href: item.url, passHref: true }
                    : {})}
                >
                  <div>
                    <CustomValue initialValue={false}>
                      {([showTooltip, setShowTooltip]) => (
                        <Tooltip
                          padding={22}
                          isOpen={showTooltip}
                          setIsOpen={setShowTooltip}
                          content={item.tooltip}
                          positions={["right"]}
                          containerClassName="z-50"
                        >
                          <li
                            key={index}
                            className={`flex rounded-md p-2 text-sm items-center gap-x-4
                                                ${
                                                  index === 0 ? "mt-2" : "mt-9"
                                                } ${
                              open && "bg-dark-color text-gray-10"
                            }
                                                ${
                                                  activePath === item.url &&
                                                  "text-green-10 bg-teal-10"
                                                } ${
                              item.disabled
                                ? "cursor-not-allowed"
                                : "cursor-pointer hover:opacity-70"
                            }`}
                            {...(item.tooltip
                              ? {
                                  onMouseEnter: () => setShowTooltip(true),
                                  onMouseLeave: () => setShowTooltip(false),
                                }
                              : {})}
                          >
                            {activePath === item.url
                              ? item.icon.active
                              : item.icon.notActive}
                            <span
                              className={`${
                                !open && "hidden"
                              } origin-left duration-200`}
                            >
                              {item.label}
                            </span>
                          </li>
                        </Tooltip>
                      )}
                    </CustomValue>
                  </div>
                </Component>
              )
            );
          })}
        </ul>
      </div>
      <div className={`sticky bottom-0`}>
        <div
          className={`p-2 lg:pt-5 pt-0 duration-300 ${
            open ? "border-t border-slate-300" : "border-t border-slate-500"
          }`}
        >
          <button
            className={`dropdown 
              flex rounded-md p-2 cursor-pointer hover:opacity-70 text-sm items-center gap-x-4
							${router.asPath === "/user-profile" && "text-green-10 bg-teal-10"}
            `}
            onClick={dropupHandler}
          >
            <Avatar
              name={userState.firstName + " " + userState.lastName}
              src={
                userState.profile
                  ? `'/images/profiles/${userState.profile}'`
                  : ""
              }
              className={`${
                userState.profile ? "pt-8 pb-4 pl-8 pr-4" : "py-1.5 px-2"
              } `}
            />
            <span
              className={`${!open && "hidden"} origin-left duration-200 w-full`}
            >
              {userState.firstName + " " + userState.lastName}
            </span>
            <ChevronDownIcon
              className={`ml-2 w-8 h-4 cursor-pointer -mt-2 ${
                !open && "hidden"
              } `}
            />
          </button>
          <ul
            className={`dropdown-menu absolute bottom-10 left-6 ${
              hidden && "hidden"
            } text-gray-10 pt-1 z-[100]`}
          >
            <li>
              <button
                onClick={handleLogout}
                className="w-full bg-white hover:bg-gray-13 py-2 px-4 block whitespace-no-wrap"
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(NavComponent);
