import { Popover, ArrowContainer } from "react-tiny-popover";
import { useEffect, forwardRef } from "react";
import clsx from "clsx";

function Tooltip(
  { timeout, isOpen, setIsOpen, content, className, children, ...props },
  ref
) {
  useEffect(() => {
    let timeoutId;

    if (timeout && isOpen) {
      timeoutId = setTimeout(() => {
        setIsOpen(false);
      }, timeout);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeout, isOpen, setIsOpen]);

  return (
    <Popover
      ref={ref}
      isOpen={isOpen}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="#201D1D"
          arrowSize={8}
        >
          <div
            className={clsx(
              "p-2 rounded-lg text-white bg-[#201D1D]",
              className
            )}
          >
            {content}
          </div>
        </ArrowContainer>
      )}
      {...props}
    >
      {children}
    </Popover>
  );
}

export default forwardRef(Tooltip);
